import React from "react"
import BackgroundImage from "../components/background-image"
import { SectionType } from "../../../types"

type SectionProps = {
  section: SectionType
  index: any
  [key: string]: any
}

const EmptyHero = ({ section, index, ...props }: SectionProps) => {
  return (
    <div className={`relative ${section.advanced?.class || ``}`} style={!section.advanced?.class ? { height: `93vh` } : undefined}>
      <BackgroundImage
        url={section.advanced?.backgroundImageLg}
        mobileUrl={section.advanced?.backgroundImageSm}
        height={section.advanced?.backgroundHeight}
        backgroundPosition={section.advanced?.backgroundPosition}
        backgroundColor={section.advanced?.backgroundColor}
        backgroundFit={section.advanced?.backgroundFit}
      />
    </div>
  )
}

export default EmptyHero
